var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "logoList" },
      _vm._l(_vm.logos, function (logo) {
        return _c(
          "div",
          { staticClass: "logoList__item" },
          [
            logo.link
              ? _c(
                  "link-wrap",
                  { attrs: { link: logo.link } },
                  [
                    _c("image-tag", {
                      attrs: {
                        image: _vm.extendImage(logo).image,
                        definition: "personaLogoList",
                      },
                    }),
                  ],
                  1
                )
              : _c("image-tag", {
                  attrs: {
                    image: _vm.extendImage(logo).image,
                    definition: "personaLogoList",
                  },
                }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }