


























import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {MasterToggleLink} from '../../../../../Interface/Links';
import {MenuService} from '../../../../../Service/MenuService';
import LinkWrap from '../../../../Misc/Link/LinkWrap/LinkWrap';

export default {
    name: 'MasterToggle',
    components: {LinkWrap},
    props: {
        context: null as PageContext
    },
    computed: {
        options(): Array<MasterToggleLink>
        {
            return MenuService.getMasterToggleLinks();
        }
    },
    methods: {
        isActive(item: MasterToggleLink): boolean
        {
            return item.href === MenuService.getHomeLink();
        },
        itemClasses(item: MasterToggleLink): PlainObject
        {
            return {
                'active': this.isActive(item)
            };
        },
        onClick(item: MasterToggleLink): void
        {
            MenuService.setHomePid(item.id);
            this.context.router.push(item.href).catch(error => {
                if (error.name != 'NavigationDuplicated') {
                    throw error;
                }
            });
        }
    }
};
