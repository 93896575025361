































import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {isArray} from '@labor-digital/helferlein/lib/Types/isArray';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import ImageTag from '../../../Component/Misc/Image/ImageTag/ImageTag.vue';
import LinkWrap from '../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'LogoList',
    components: {ImageTag, LinkWrap},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        logos(): Array<PlainObject>
        {
            const result = this.context.data.get('logos', []);
            if (!isArray(result)) {
                return [];
            }
            return result;
        }
    },
    methods: {
        extendImage(logo: PlainObject): PlainObject
        {
            const logoNew = {...logo};
            logoNew.image.image.title = logo.company;
            return logoNew;
        }
    }
};
