var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "masterToggle" },
    _vm._l(_vm.options, function (item) {
      return _c(
        "li",
        { class: _vm.itemClasses(item) },
        [
          _c(
            "link-wrap",
            {
              attrs: { link: item.href, "emit-on-click": "" },
              on: {
                click: function ($event) {
                  return _vm.onClick(item)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t(item.title)))]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }